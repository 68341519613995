import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../common/data-table-component/data-table-component'
import axios from '../../../api/axios';
import { Button, IconButton, Input } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CommonModel from '../../Models/common-model';

export default function USJNetMembersComponent() {

  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
const [emailCreateData,setEmailCreateData] = useState([]);

useEffect(()=>{

  setIsLoading(true);
  axios.get("/usjnet-member-details")
      .then(async function (response) {
        if(response.status === 200){
            setEmailCreateData(response.data);
            setIsLoading(false);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
},[])

    const columns = [
    { id: 'id', label: 'ID', minWidth: 10 },
    { id: 'empNo', label: 'Emp No', minWidth: 80 },
    { id: 'name', label: 'Name', minWidth: 200 },
    { id: 'mobileNo', label: 'Mobile No', minWidth: 170 },
    { id: 'sjpEmail', label: 'Sjp Email', minWidth: 170 },
    {
        id: 'action',
        label: 'Action',
        minWidth: 170,
        align: 'center',
        
    },
    
  ];

    useEffect(()=>{
    console.log(emailCreateData[76]?.personal_details[0]?.employee_no);
      var res = emailCreateData.map((val,index)=>({
          id : index+1,
          name: val?.personal_details[0]?.title_name + " " +val?.personal_details[0]?.initials + " "+ val?.personal_details[0]?.last_name   ,
          empNo: val?.emp_no,
          mobileNo:val?.personal_details[0]?.mobile_no,
          sjpEmail:val?.personal_details[0]?.email,
          action:<>
             <IconButton onClick={() => alert()}>
                <SettingsOutlinedIcon />
              </IconButton>
          </>,
      }));
    setRowData(res);

  },[emailCreateData])

  const data = [{ id: 1, firstName: 'John', lastName: 'Doe' },{ id: 2, firstName: 'John', lastName: 'Doe' }];

   const renderRowExpanded = rowData => {
    return (
      <div>
        <div
          style={{
            width: 60,
            height: 60,
            float: 'left',
            marginRight: 10,
            background: '#eee'
          }}
        >
          <img src={rowData.avatar} style={{ width: 60 }} />
        </div>
        <p>Email: {rowData.email}</p>
        <p>Phone: {rowData.phone}</p>
      </div>
    );
  };

  return (
    <>
    <div class="product__discount">
        <div class="section-title product__discount__title">
            <h2>USJnet Members</h2>
            <div style={{ padding:20 }}></div>
            <DataTableComponent columns={columns} data={rowData} />
        </div>
        
    </div>
    <CommonModel openModel = {false}/>
    </>
     
  )
}

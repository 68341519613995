// import React, { useEffect, useState } from 'react';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from '@mui/material';

// const CommonModel = ({openModel}) => {
//   const [open, setOpen] = useState(false); // State to manage modal open/close

// useEffect(()=>{
//     setOpen(openModel);
// },[openModel])

//   // Function to open the modal
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   // Function to close the modal
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       {/* Button to trigger modal opening */}
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Open Modal
//       </Button>

//       {/* Modal dialog */}
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>User Details</DialogTitle>
//         <DialogContent>
          
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleClose} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default CommonModel;

import React, { useEffect, useState } from 'react';
import {
    Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const CommonModel = ({openModel}) => {
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    setOpen(openModel);
},[openModel])

  // User details state
  const [userDetails, setUserDetails] = useState({
    name: 'John Doe',
    designation: 'Software Engineer',
    email: 'johndoe@example.com',
    mobileNo: '123-456-7890',
  });

  // State to manage editable fields
  const [isEditing, setIsEditing] = useState({
    email: false,
    mobileNo: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Toggle edit mode
  const toggleEdit = (field) => {
    setIsEditing((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Show User Details
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>

            <Box 
      sx={{ 
        padding: 3, 
        border: '1px solid #e0e0e0', 
        borderRadius: 2, 
        minWidth: 400,
        maxWidth: 400, 
        backgroundColor: 'white', 
        boxShadow: 0
      }}
    >
      {/* User Name */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
            Name:
          </Typography>
          <Typography variant="body1">Mr. Chathuranga</Typography>
        </Box>
        <IconButton size="small" color="primary">
          <EditOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />

      {/* User Email */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
            Email:
          </Typography>
          <Typography variant="body1">
            <Link href="mailto:abc@gmail.com" underline="hover">
              abc@gmail.com
            </Link>
          </Typography>
        </Box>
        <IconButton size="small" color="primary">
          <EditOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />

      {/* User Mobile Number */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
            Mobile No:
          </Typography>
          <Typography variant="body1"><Input value="0712663534" /></Typography>
        </Box>
        <IconButton size="small" color="primary">
          <EditOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommonModel;


import React, { Fragment, useState } from 'react'
import { List, FlexboxGrid } from 'rsuite';
import ImageIcon from '@rsuite/icons/legacy/Image';
import FilmIcon from '@rsuite/icons/legacy/Film';
import UserCircleIcon from '@rsuite/icons/legacy/UserCircleO';
import GoImage from '../../assets/go.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useServices from '../../hooks/useServices';
import Cookies from 'universal-cookie';

const data = [
  {
    title: 'Apply for my promotion',
    icon: <ImageIcon />,
  },
  {
    title: 'Apply for my increment',
    icon: <ImageIcon />,
  },
  {
    title: 'Vehicle Pass',
    icon: <FilmIcon />,
  },
  {
    title: 'Research Allowance',
    icon: <FilmIcon />,
  },
  {
    title: 'Insurance',
    icon: <ImageIcon />,
  },
  {
    title: 'Overseas Leave',
    icon: <ImageIcon />,
  }
];

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '15px',
  
};

const slimText = {
  fontSize: '0.666em',
  color: '#97969B',
  fontWeight: 'lighter',
  paddingBottom: 5
};

const titleStyle = {
  paddingBottom: 5,
  whiteSpace: 'nowrap',
  fontWeight: 500
};

const dataStyle = {
  fontSize: '1.2em',
  fontWeight: 500
};

const StyledLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    color: #990000;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
  }
`;

export default function LeftSideListComponent({initialData,isLoading}) {

  const navigate = useNavigate();
  const [service] = useServices();
const cookies = new Cookies();

const renderRaise = React.useCallback(number => {
    const isPositive = number > 0;
    const isNegative = number < 0;
    return (
      <span style={{ paddingLeft: 15, color: isNegative ? 'red' : 'green' }}>
        <span>{isPositive ? '+' : null}</span>
        <span>{number}</span>
      </span> 
    );
  }, []);

  const LoadIframe = (url,IsPassRegNoWithURL) => {
    if(IsPassRegNoWithURL){
        service.setIFrameURL(url+cookies.get('empNo'));
        navigate("/apply-application");
    }else{
        service.setIFrameURL(url);
        navigate("/apply-application");
    }
  }

  return (
    <Fragment>
        <List hover style={{ maxHeight:'380px' }}>
          {!isLoading && (
            <>
            {initialData?.ApplicationList.map((item, index) => (
         <StyledLink onClick={()=>LoadIframe(item?.navigate,item?.IsPassRegNoWithURL)}>
        <List.Item key={item.name} index={index + 1}>
          <FlexboxGrid>
            {/*icon*/}
           
            <FlexboxGrid.Item colspan={2} style={styleCenter}>
              {/* {React.cloneElement(<ImageIcon />, {
                style: {
                  color: 'darkgrey',
                  fontSize: '1em'
                }
              })} */}
            </FlexboxGrid.Item>
            {/*base info*/}
            <FlexboxGrid.Item
              colspan={20}
              style={{
                ...styleCenter,
                flexDirection: 'column',
                alignItems: 'flex-start',
                overflow: 'hidden'
              }}
            >
              <div style={titleStyle}>{item.name}</div>
              {/* <div style={slimText}>
                <div>
                  <UserCircleIcon />
                  {' ' + item['creator']}
                </div>
                <div>{item['date']}</div>
              </div> */}
            </FlexboxGrid.Item>
            
           
            {/*uv data*/}
            <FlexboxGrid.Item
              colspan={1}
              style={{
                ...styleCenter,
                marginRight:"10px"
              }}
            >
              <img src={GoImage} alt="logo" style={{ maxWidth:23 }} />

            </FlexboxGrid.Item>
           
          </FlexboxGrid>
        </List.Item>
         </StyledLink>
      ))}
            </>
          )}
      
    </List>
  
    </Fragment>
  )
}

// import React from 'react'
// import { IconButton, Table, Tag } from 'rsuite';
//   import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
//   import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
 

//   const { Column, HeaderCell, Cell } = Table;

//   const rowKey = 'id';

//   const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
//     <Cell {...props} style={{ padding: 5 }}>
//       <IconButton
//         appearance="subtle"
//         onClick={() => {
//           onChange(rowData);
//         }}
//         icon={
//           expandedRowKeys.some(key => key === rowData[rowKey]) ? (
//             <CollaspedOutlineIcon />
//           ) : (
//             <ExpandOutlineIcon />
//           )
//         }
//       />
//     </Cell>
//   );

 

// export default function DataTableComponent({columns=[],data=[],renderRowExpanded}) {

//     const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

//     const handleExpanded = (rowData, dataKey) => {
//       let open = false;
//       const nextExpandedRowKeys = [];

//       expandedRowKeys.forEach(key => {
//         if (key === rowData[rowKey]) {
//         open = true;
//       } else {
//         nextExpandedRowKeys.push(key);
//       }
//     });

//     if (!open) {
//       nextExpandedRowKeys.push(rowData[rowKey]);
//     }

//     setExpandedRowKeys(nextExpandedRowKeys);
//   };

//   return (
//      <Table
//      wordWrap="break-word"
//       shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
//       height={300}
//       data={data}
//       rowKey={rowKey}
//       expandedRowKeys={expandedRowKeys}
//       onRowClick={data => {
//         console.log(data);
//       }}
//       renderRowExpanded={renderRowExpanded}
//     >
//       <Column width={70} align="center">
//         <HeaderCell>#</HeaderCell>
//         <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
//       </Column>

//       {columns.map(column => (
//            <Column key={column.key} width={column.width} >
//           <HeaderCell style={{ color: 'black', fontSize: 14 }}>{column.header}</HeaderCell>
//           <Cell style={{ whiteSpace: 'pre-wrap' }} dataKey={column.dataKey} />
//         </Column>
//       ))}
      

      
//     </Table>
 
//   )
// }


import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// Define the table columns
// const columns = [
//   { id: 'name', label: 'Name', minWidth: 170 },
//   { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
//   {
//     id: 'population',
//     label: 'Population',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'size',
//     label: 'Size\u00a0(km\u00b2)',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'density',
//     label: 'Density',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
// ];

// Helper function to create row data
function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

// Example row data
// const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767),
// ];

// Main component for rendering the table
export default function DataTableComponent({columns=[],data=[]}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}


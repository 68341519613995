import { Image, Padding } from '@mui/icons-material'
import React, { Fragment } from 'react'
import { BaseUrl } from '../../../api/axios';
import LogoImage from "../../../assets/logo.png";
import { Box, Grid2, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Address from "../../../assets/cits-address_2.png";

export default function FooterComponent() {
  return (
    <Fragment>
        <div style={{ padding:50 }}></div>
        <footer class="" style={{ backgroundColor:"#990000" }}>
        <div class="container" >
            <div class="row">
                <div class="col-lg-5    ">
                    <div class="">
                        {/* <div class="footer__about__logo">
                            <a href={`${BaseUrl}/home`}><img src={LogoImage} alt="logo" style={{ marginRight: "20px" }} height={40} /></a>
                        </div>
                        <ul>
                            <li>Address: 60-49 Road 11378 New York</li>
                            <li>Phone: +65 11.188.888</li>
                            <li>Email: hello@colorlib.com</li>
                        </ul> */}

                        <div style={{ display:'flex',flexDirection:'row',marginTop:20 }}>
                            <div style={{ marginTop:20 }}> <img src={LogoImage} alt="CITS" style={{ width: '100%', height: 'auto' }} /></div>

                            <div> <img src={Address} alt="CITS" style={{ width: '100%', height: 'auto' }} /></div>
                        </div>
 
                    </div>
                </div>
                {/* <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
                    <div class="footer__widget">
                        {/* <h6>Useful Links</h6>
                        <ul>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">About Our Shop</a></li>
                            <li><a href="#">Secure Shopping</a></li>
                            <li><a href="#">Delivery infomation</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Our Sitemap</a></li>
                        </ul>
                        <ul>
                            <li><a href="#">Who We Are</a></li>
                            <li><a href="#">Our Services</a></li>
                            <li><a href="#">Projects</a></li>
                            <li><a href="#">Contact</a></li>
                            <li><a href="#">Innovation</a></li>
                            <li><a href="#">Testimonials</a></li>
                        </ul> 
                    </div>
                </div> */}
               
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer__copyright">
                        <div class="footer__copyright__text"><p style={{ color:'white' }}>
  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | CITS
  </p></div>
                        {/* <div class="footer__copyright__payment"><img src="img/payment-item.png" alt=""></div> */}
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </Fragment>
  )
}

import React, { Fragment } from 'react';
import styled from 'styled-components';
import BackImage from "../../../assets/banner.png";

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 16px; /* Increased font size */


  &:hover {
    color: #990000;
    text-decoration: none;
    font-size: 16px; /* Increased hover font size */
  }
`;

const BannerContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-image: url(${BackImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px;
  height: 350px;

  @media (max-width: 1200px) {
    height: 300px; /* Adjust height for medium screens */
  }

  @media (max-width: 768px) {
    height: 250px; /* Adjust height for tablet screens */
    justify-content: center; /* Center content for smaller screens */
  }

  @media (max-width: 480px) {
    height: 200px; /* Adjust height for mobile screens */
    padding: 0 15px; /* Add some padding for mobile */
    background-position: top center; /* Adjust the background position for mobile */
  }
`;


const ListContainer = styled.div`
  margin: 0px 0; /* Add some spacing between items */
  padding: 5px;
`;

export default function SectionTwoComponent({ initialData, isLoading }) {
  return (
    <>
    <BannerContainer>
      <Fragment>
        <section style={{ marginLeft:"7%" }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div>
                  <h4 style={{ marginLeft:5 }}>Approvals</h4>
                  {!isLoading && (
                    <>
                      {initialData?.approvalList.map((val) => (
                        <ListContainer key={val?.name}>
                          <StyledLink href="">
                            <span>{val?.name}</span>
                          </StyledLink>
                        </ListContainer>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className=" col-sm-6">
                <div>
                  <h4 style={{ marginLeft:5 }}>Recommendations</h4>
                  {!isLoading && (
                    <>
                      {initialData?.recommendationList.map((val) => (
                        <ListContainer key={val?.name}>
                          <StyledLink href="">
                            <span>{val?.name}</span>
                          </StyledLink>
                        </ListContainer>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    </BannerContainer>
    </>
    
  );
}
